import React, { memo, useMemo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import {
    ButtonWrapper,
    IconImage,
    DrawerItem,
    DrawerItemLeft,
    DrawerItemText,
    GreetingWrapper,
    ProfileDrawerHeader,
    GreetingTitle,
    GreetingSubtitle,
} from './header-section-styles'
import Drawer, { Direction } from '../../common/drawer'
import { HeaderSectionTransport } from '../../../transports/region-landing-page-transport'
import { useTranslation } from '../../../hooks/locale'

import CloseRegularIcon from '../../../assets/icons/close-regular.svg'
import RightIcon from '../../../assets/icons/chevron-right.svg'
import LandlordAreaIcon from '../../../assets/icons/userbar-icons/usermenu-icons/layout.svg'
import BookingIcon from '../../../assets/icons/userbar-icons/bookings.svg'
import LandlordListingsIcon from '../../../assets/icons/userbar-icons/usermenu-icons/megaphone.svg'
import LandlordInvoicesIcon from '../../../assets/icons/userbar-icons/usermenu-icons/file-text.svg'
import PersonalDetailsIcon from '../../../assets/icons/userbar-icons/personal-details.svg'
import LogoutIcon from '../../../assets/icons/userbar-icons/log-out.svg'

interface Props {
    open: boolean
    onClose: () => void
    headerSection: HeaderSectionTransport
    isLandlordRole?: boolean
    isGuestRole?: boolean
    firstName?: string
    inquiryCount?: number
}

const ProfileDrawer: React.FC<Props> = ({
    open,
    onClose,
    headerSection,
    isLandlordRole,
    isGuestRole,
    firstName,
    inquiryCount,
}) => {
    const { t } = useTranslation()

    const {
        logoutButton,
        guestProfileButton,
        landlordProfileButton,
        userBookingsButton,
        guestInquiriesButton,
        landlordInquiriesButton,
        landlordListingsButton,
        landlordInvoicesButton,
        landlordAreaButton,
    } = headerSection.navigationSectionResponse.navigation

    const drawerHeight = useMemo(() => (isLandlordRole ? 470 : 315), [isLandlordRole])

    return (
        <Drawer open={open} onClose={onClose} direction={Direction.bottom} size={drawerHeight} id="usersidebar">
            <ProfileDrawerHeader>
                <ButtonWrapper onClick={onClose}>
                    <IconImage as={CloseRegularIcon as AnyStyledComponent} />
                </ButtonWrapper>
                <GreetingWrapper>
                    <GreetingTitle>
                        {t('userGreet')} {firstName}
                    </GreetingTitle>
                    <GreetingSubtitle>
                        {isLandlordRole ? t('landlordUserLoginMsg') : t('guestUserLoginMsg')}
                    </GreetingSubtitle>
                </GreetingWrapper>
            </ProfileDrawerHeader>
            {isLandlordRole && !!landlordAreaButton?.url && (
                <DrawerItem href={landlordAreaButton.url} onClick={onClose} noCrawl={landlordAreaButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={LandlordAreaIcon as AnyStyledComponent} />
                        <DrawerItemText>{landlordAreaButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {isGuestRole && !!guestInquiriesButton?.url && (
                <DrawerItem href={guestInquiriesButton.url} onClick={onClose} noCrawl={guestInquiriesButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={BookingIcon as AnyStyledComponent} />
                        <DrawerItemText>
                            {guestInquiriesButton.text}
                            {inquiryCount ? ` [${inquiryCount}]` : ''}
                        </DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {isLandlordRole && !!landlordInquiriesButton?.url && (
                <DrawerItem
                    href={landlordInquiriesButton.url}
                    onClick={onClose}
                    noCrawl={landlordInquiriesButton.noCrawl}
                >
                    <DrawerItemLeft>
                        <IconImage as={BookingIcon as AnyStyledComponent} />
                        <DrawerItemText>
                            {landlordInquiriesButton.text}
                            {inquiryCount ? ` [${inquiryCount}]` : ''}
                        </DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {!!userBookingsButton?.url && (
                <DrawerItem href={userBookingsButton.url} onClick={onClose} noCrawl={userBookingsButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={BookingIcon as AnyStyledComponent} />
                        <DrawerItemText>{userBookingsButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {isLandlordRole && !!landlordListingsButton?.url && (
                <DrawerItem
                    href={landlordListingsButton.url}
                    onClick={onClose}
                    noCrawl={landlordListingsButton.noCrawl}
                >
                    <DrawerItemLeft>
                        <IconImage as={LandlordListingsIcon as AnyStyledComponent} />
                        <DrawerItemText>{landlordListingsButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {isLandlordRole && !!landlordInvoicesButton?.url && (
                <DrawerItem
                    href={landlordInvoicesButton.url}
                    onClick={onClose}
                    noCrawl={landlordInvoicesButton.noCrawl}
                >
                    <DrawerItemLeft>
                        <IconImage as={LandlordInvoicesIcon as AnyStyledComponent} />
                        <DrawerItemText>{landlordInvoicesButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {isGuestRole && !!guestProfileButton?.url && (
                <DrawerItem href={guestProfileButton.url} onClick={onClose} noCrawl={guestProfileButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={PersonalDetailsIcon as AnyStyledComponent} />
                        <DrawerItemText>{guestProfileButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {isLandlordRole && !!landlordProfileButton?.url && (
                <DrawerItem href={landlordProfileButton.url} onClick={onClose} noCrawl={landlordProfileButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={PersonalDetailsIcon as AnyStyledComponent} />
                        <DrawerItemText>{landlordProfileButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {!!logoutButton?.url && (
                <DrawerItem href={logoutButton.url} onClick={onClose} noCrawl={logoutButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={LogoutIcon as AnyStyledComponent} />
                        <DrawerItemText>{logoutButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
        </Drawer>
    )
}

export default memo(ProfileDrawer)
