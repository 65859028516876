import { useCallback, useEffect, useState } from 'react'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { landingPageEventActions } from '../../services/analytics-service/actions'
import { landingPageEventLabels } from '../../services/analytics-service/labels'
import AnalyticsService from '../../services/analytics-service'
import ButtonTransport from '../../transports/common/button-transport'
import { useSelector } from 'react-redux'
import RootState from '../../redux/types'
import { useUserDetails } from '../../hooks/users'
import { Role } from '../search-result-listing/header-section/types'
import { useInView } from 'react-intersection-observer'
import { trackListings } from './utils'
import { ListingSectionObject } from '../../transports/common/listing-section-transport'
import { getSearchDate } from '../../utils/miscellaneous-utils'
import { CookieManagerService } from '../../services'

const LISTING_VIEW_EVENT_ID = 10
const LISTING_CLICK_EVENT_ID = 1

const TOP_OFFER_VIEW_EVENT_ID = 15
const TOP_OFFER_CLICK_EVENT_ID = 16

const TOP_LISTING_VIEW_EVENT_ID = 13
const TOP_LISTING_CLICK_EVENT_ID = 14

const PREMIUM_TOP_LISTING_VIEW_EVENT_ID = 19
const PREMIUM_TOP_LISTING_CLICK_EVENT_ID = 20

const RESULT_LISTING_VIEW_EVENT_ID = 21

const RLP_POPULAR_FILTER = 'region_landingpage_popular_filter'
const RLP_TOP_FILTER = 'region_landingpage_top_rating'

const SRL_PAGE_SIZE = 25
let componentName = 'unknown'

const _trackingIntersectionObserverOptions = {
    root: null,
    threshold: 0.99,
    triggerOnce: true,
}

export const useLoadMoreSlides = ({
    currentSlideIndex,
    visibleSlides,
    currentTotalSlideCount,
    loadMoreSlides,
    finalTotalSlideCount,
}: {
    currentSlideIndex: number
    visibleSlides: number
    currentTotalSlideCount: number
    loadMoreSlides: () => void
    finalTotalSlideCount: number
}) => {
    const _validRangeStartIndex = currentSlideIndex + visibleSlides
    useEffect(() => {
        if (currentTotalSlideCount > visibleSlides && currentTotalSlideCount < finalTotalSlideCount) {
            if (visibleSlides === 1) {
                if (currentSlideIndex === currentTotalSlideCount - 2) {
                    loadMoreSlides()
                }
            } else {
                if (
                    _validRangeStartIndex <= currentTotalSlideCount - 1 &&
                    currentTotalSlideCount - 1 <= _validRangeStartIndex + visibleSlides - 1
                ) {
                    loadMoreSlides()
                }
            }
        }
    }, [
        currentTotalSlideCount,
        visibleSlides,
        currentSlideIndex,
        _validRangeStartIndex,
        loadMoreSlides,
        finalTotalSlideCount,
    ])
}

export const useLandingPageHeader = ({ url, listRentalButton }: { url: string; listRentalButton: ButtonTransport }) => {
    const wishlistCount = useSelector((state: RootState) => state.wishlist.count)
    const { userDetails } = useUserDetails()
    const isLandlordRole = userDetails?.tfwPrimaryRole === Role.CUSTOMER
    const isGuestRole = userDetails?.tfwPrimaryRole === Role.TOURIST
    const inquiryCount = userDetails?.inquiryCount

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isUserSidebarOpen, setIsUserSidebarOpen] = useState(false)
    const toggleSidebar = useCallback(() => setIsSidebarOpen(!isSidebarOpen), [isSidebarOpen, setIsSidebarOpen])
    const toggleUserSidebar = useCallback(
        () => setIsUserSidebarOpen(!isUserSidebarOpen),
        [isUserSidebarOpen, setIsUserSidebarOpen],
    )

    const [popUpBoxVisible, setPopUpBoxVisible] = useState(false)
    const togglePopUpBoxVisibility = useCallback(
        (isVisible: boolean) => setPopUpBoxVisible(isVisible),
        [setPopUpBoxVisible],
    )

    const listRentalClickHandler = useCallback(() => {
        if (listRentalButton.url) {
            const eventCategory = landingPageEventCategories.headerSection.RENTAL
            const eventAction = landingPageEventActions.headerSection.RENTAL
            const eventLabel = landingPageEventLabels.headerSection.RENTAL
            AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: eventLabel })

            window.location.href = listRentalButton.url
        }
    }, [listRentalButton.url])

    const wishlistClickHandler = useCallback(() => {
        const eventCategory = `${landingPageEventCategories.headerSection.WISHLIST} - ${url}`
        const eventAction = landingPageEventActions.headerSection.WISHLIST
        const eventLabel = landingPageEventLabels.headerSection.WISHLIST
        AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: eventLabel })
    }, [url])

    return {
        isSidebarOpen,
        toggleSidebar,
        isUserSidebarOpen,
        toggleUserSidebar,
        popUpBoxVisible,
        togglePopUpBoxVisibility,
        listRentalClickHandler,
        wishlistClickHandler,
        wishlistCount,
        isLandlordRole,
        isGuestRole,
        inquiryCount,
    }
}

/*https://oyorooms.atlassian.net/browse/TRAUM-5700*/
const getTrackingArray = (
    viewTracking: boolean,
    propertyObject: ListingSectionObject,
    listingType?: string | null,
    index?: number,
) => {
    const trackingArray = []
    const date = new Date()
    const dateString = getSearchDate(date)
    const timeString = date.toLocaleTimeString('en-US', { hour12: false })
    const _url = window.location.href
    const _timestamp = `${dateString} ${timeString}`
    const _eventValue = propertyObject.id

    const _resultListingViewEventData = {
        url: _url,
        filters: {
            region_title: propertyObject.seoRegion.name ?? '',
            region_id: propertyObject.regionId ?? '',
            adult: 0,
            arrival_date: null,
            children: 0,
            departure_date: null,
        },
        listing: {
            tfw_object_id: propertyObject.id,
            provider_id: propertyObject.providerId,
            is_premium_top_listing: !!propertyObject.isPremiumTopObject,
            availability: propertyObject.availabilityState ? propertyObject.availabilityState : 'on_request',
            external_object_id: propertyObject.externalObjectId,
            features: Object.keys(propertyObject.features),
            index: index ?? 1,
            is_combo_listing: propertyObject.isComboListing,
            page: index ? Math.ceil(index / SRL_PAGE_SIZE) : 1,
            price: propertyObject.price,
            component: {
                name: 'unknown',
            },
        },
        google_analytics: [
            {
                key: '_gid',
                value: CookieManagerService.getCookie('_gid'),
            },
            {
                key: '_ga',
                value: CookieManagerService.getCookie('_ga'),
            },
        ],
        timestamp: _timestamp,
    }

    if (
        propertyObject.isTopObject &&
        listingType !== 'RED' &&
        listingType !== 'TOP_RATED' &&
        listingType !== 'POPULAR_FILTER_BASED'
    ) {
        viewTracking
            ? trackingArray.push({ eventType: TOP_LISTING_VIEW_EVENT_ID, eventValue: _eventValue })
            : trackingArray.push({ eventType: TOP_LISTING_CLICK_EVENT_ID, eventValue: _eventValue })
        return trackingArray
    } else if (listingType === 'RED') {
        viewTracking
            ? trackingArray.push({ eventType: TOP_OFFER_VIEW_EVENT_ID, eventValue: _eventValue })
            : trackingArray.push({ eventType: TOP_OFFER_CLICK_EVENT_ID, eventValue: _eventValue })
        return trackingArray
    } else if (propertyObject.isPremiumTopObject) {
        viewTracking
            ? trackingArray.push({
                  eventType: PREMIUM_TOP_LISTING_VIEW_EVENT_ID,
                  eventValue: _eventValue,
              })
            : trackingArray.push({
                  eventType: PREMIUM_TOP_LISTING_CLICK_EVENT_ID,
                  eventValue: _eventValue,
              })
    } else {
        /*https://oyorooms.atlassian.net/browse/TRAUM-6507*/
        if (listingType === 'POPULAR_FILTER_BASED') {
            _resultListingViewEventData.listing.component.name = RLP_POPULAR_FILTER
            componentName = RLP_POPULAR_FILTER
        } else if (listingType === 'TOP_RATED') {
            _resultListingViewEventData.listing.component.name = RLP_TOP_FILTER
            componentName = RLP_TOP_FILTER
        }

        viewTracking
            ? trackingArray.push(
                  { eventType: LISTING_VIEW_EVENT_ID, eventValue: _eventValue },
                  {
                      eventType: RESULT_LISTING_VIEW_EVENT_ID,
                      eventValue: _eventValue,
                      eventData: _resultListingViewEventData,
                  },
              )
            : trackingArray.push({ eventType: LISTING_CLICK_EVENT_ID, eventValue: _eventValue })

        if (!viewTracking) {
            CookieManagerService.setCookie('srlObjectClicked_' + propertyObject.id, window.location.href)
            CookieManagerService.setCookie('srlObjectClicked_' + propertyObject.id + '_componentName', componentName)
        }
    }

    return trackingArray
}

export const usePropertyCardTracking = (
    propertyObject: ListingSectionObject,
    listingType?: string | null,
    index?: number,
) => {
    const [intersectionTargetRef, inView] = useInView(_trackingIntersectionObserverOptions)
    useEffect(() => {
        if (inView) {
            trackListings(getTrackingArray(true, propertyObject, listingType, index))
        }
    })

    const propertyCardClickTracking = useCallback(
        (propertyObject: ListingSectionObject, listingType?: string | null) => {
            trackListings(getTrackingArray(false, propertyObject, listingType))
        },
        [],
    )

    return { intersectionTargetRef, propertyCardClickTracking }
}
