import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUserDetails } from '../redux/user/actions'
import { getUserDetailsModel, getUserDetailsTransportResponseState } from '../redux/user/selectors'
import { UserDetailsParamsTransport } from '../transports/user-details-transport'

export const useUserDetails = ({ additionalDetails }: UserDetailsParamsTransport = {}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserDetails({ params: { additionalDetails } }))
    }, [dispatch, additionalDetails])

    const { isLoading, error } = useSelector(getUserDetailsTransportResponseState)
    const userDetails = useSelector(getUserDetailsModel)

    return { userDetails, isLoading, error, isLoggedIn: !!userDetails?.id }
}
