import { tabletBreakpoint, desktopBreakpoint } from 'react-components'
import styled from 'styled-components'

export const Container = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    margin: 2.5rem 1.5rem 0.75rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 2.5rem 1.5rem 0.75rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 3.75rem 2.5rem 0;
    }
`
export const MainTitle = styled.h2`
    font-family: ${props => props.theme.typography.font.family.yummoVerdana};
    font-size: ${props => props.theme.typography.font.size.large};
    font-weight: 700;
    line-height: 1.75rem;
    margin: 0;
    color: ${props => props.theme.colors.tfwBlack87};
    letter-spacing: -0.35px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        letter-spacing: -1px;
        font-size: 2.25rem;
        line-height: 2.625rem;
    }
`

export const DirectoryItemsContainer = styled.div`
    padding: 0 0 1rem;
`
