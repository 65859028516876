import React, { FC, memo, useCallback } from 'react'
import { useTranslation } from '../../hooks/locale'
import { Container, MainTitle, DirectoryItemsContainer } from './directory-section-styles'
import { DirectoryItemTransport } from '../../transports/directory-transport'
import DirectoryItem from './directory-item' // Import as default export

interface DirectoryPageProps {
    region: string
    directoryItems: DirectoryItemTransport[]
}

const DirectorySection: FC<DirectoryPageProps> = ({ region, directoryItems }) => {
    const { t } = useTranslation()

    const TitleCase = useCallback((sentence: string) => {
        return sentence
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ')
    }, [])

    return (
        <Container>
            <MainTitle>{`${t('directoryHeading')} ${TitleCase(region)}`}</MainTitle>
            <DirectoryItemsContainer>
                {directoryItems.map((data, index) => data.links.length > 0 && <DirectoryItem key={index} {...data} />)}
            </DirectoryItemsContainer>
        </Container>
    )
}

export default memo(DirectorySection)
