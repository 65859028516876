import styled from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'

export const FooterWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colors.tfwWhite64};
    border-top: 1px solid ${props => props.theme.colors.tfwBlackOpacity10};
`

export const InnerContainerWrapper = styled.div`
    max-width: 87.5rem;
    margin: 0 auto;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        background-color: ${props => props.theme.colors.tfwWhite64};
        padding: 2.5rem ${props => props.theme.spacing.large} 2rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 3.75rem ${props => props.theme.spacing.large} 3rem;
    }
`

export const GridContainer = styled.div`
    max-width: 87.5rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 auto;
    }
`
export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-wrap: ${props => props.theme.style.flexWrap.wrap};
    padding: 0.375rem 0.375rem 1rem 1rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${props => props.theme.style.flexDirection.row};
        padding: 0 0.313rem;
    }
`

export const LogoImage = styled.a`
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 30%;
        float: left;
        text-align: left;
    }
`
export const ImageWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 25%;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 33.33%;
    }
`
export const LogoImagePic = styled.img`
    height: auto;
    width: ${props => props.theme.layout.fill};
`
export const Image = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`
export const IconContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: ${props => props.theme.style.justifyContent.flexEnd};
    width: 75%;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 33.33%;
        justify-content: ${props => props.theme.style.justifyContent.center};
    }
`
export const IconUl = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 1.6;
`
export const IconLi = styled.li`
    display: inline-block;
    padding-left: 0.188rem;
    padding-right: 0.188rem;
    border-radius: 100%;
    border: 1px solid ${props => props.theme.colors.white};
    transition-duration: 0.5s;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        border: 1px solid ${props => props.theme.colors.tfwWhite64};
        :hover {
            border: 1px solid ${props => props.theme.colors.tfwBlack87};
        }
    }
`
export const IconContent = styled.a`
    display: ${props => props.theme.style.display.block};
    cursor: pointer;
    padding: 0.625rem;
`

export const IconImage = styled.img`
    height: 1.25rem;
`

export const Slide = styled.a`
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-style: normal;
    color: ${props => props.theme.colors.tfwBlack87};
    display: inline-block;
    padding: 0.313rem 0;
    font-weight: 400;
    font-size: ${props => props.theme.typography.font.size.small};
    text-decoration: none;
    transition-duration: 0.2s;
    letter-spacing: 0.2px;
    line-height: 1.5;
    :hover {
        text-decoration: underline;
    }
`

export const MenuWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    padding: 1.25rem 1.875rem;
    margin: 0 auto;
    max-width: 87.5rem;
    height: 100%;
    overflow: scroll;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        overflow: unset;
        padding: 0;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid ${props => props.theme.colors.tfwBlackOpacity10};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-bottom: 1.5rem;
    }
`
export const Title = styled.span`
    font-size: ${props => props.theme.typography.font.size.small};
    margin: 0 0 0.625rem;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-weight: 900;
    line-height: 1.6;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.small};
        margin: 0 0 0.187rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
        margin: 0 0 1.25rem;
    }
`

export const Ol = styled.ol`
    list-style: none;
    line-height: 1.6;
    padding: 0;
    margin: 0 0 1.875rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: 0;
    }
`
export const Ul = styled.ul`
    list-style: none;
    line-height: 1.6;
    padding: 0;
    margin: 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: left;
        padding-bottom: 2.5rem;
        border: 0 solid;
        border-bottom: 0.063rem ${props => props.theme.colors.tfwBlack12};
    }
`
export const ListWrapper = styled.li`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        float: left;
        width: 16.66%;
        padding: 0 0.313rem;
    }
`

export const FooterButton = styled.button`
    border: none;
    background-color: #e0e0e0;
    width: 100%;
    margin: 10px;
    padding: 15px;
    color: #212121;
    margin-top: 25px;
    border-radius: 6px;
    font-size: 16px;
`