import React, { FC, Fragment, memo, useCallback, useContext, useState } from 'react'
import { Footer, ScreenContext, Sidebar } from 'react-components'
import { AnyStyledComponent } from 'styled-components'

import {
    Container,
    FooterWrapper,
    GridContainer,
    IconContainer,
    IconContent,
    IconImage,
    IconLi,
    IconUl,
    Image,
    ImageWrapper,
    InnerContainerWrapper,
    ListWrapper,
    LogoImage,
    LogoImagePic,
    MenuWrapper,
    Ol,
    Slide,
    Title,
    Ul,
    FooterButton
} from './footer-section-style'
import { useTranslation } from '../../hooks/locale'
import ExternalLink from '../common/external-link'

import CloseRegularIcon from '../../assets/icons/close-regular.svg'
import FacebookIcon from '../../assets/icons/footer-icons/facebook.svg'
import InstagramIcon from '../../assets/icons/footer-icons/instagram.svg'
import PinterestIcon from '../../assets/icons/footer-icons/pinterest.svg'
import FooterSectionTransport from '../../transports/common/footer-section-transport'
import ButtonTransport from '../../transports/common/button-transport'

interface Props {
    footerSection: FooterSectionTransport
    footerLogo: ButtonTransport
    socialMediaLinks: ButtonTransport[]
}

const FooterSection: FC<Props> = ({ footerSection: { footerGroups }, footerLogo, socialMediaLinks }) => {
    const [visible, setVisible] = useState(false)
    const toggleSidebar = useCallback(() => {
        setVisible(prevVisible => !prevVisible);
    }, [])

    const { isMobile } = useContext(ScreenContext)
    const { t } = useTranslation()

    return (
        <Footer id="footer">
            <FooterWrapper>
                <InnerContainerWrapper>
                    {!isMobile && (
                        <MenuWrapper>
                            <Ul>
                                {footerGroups.map(item => (
                                    <ListWrapper key={item.groupName}>
                                        <Ol>
                                            <li>
                                                <Title>{item.groupName}</Title>
                                            </li>
                                            {item.footerLinkItems.map(footerLinkItem => (
                                                <li key={footerLinkItem.text}>
                                                    <ExternalLink
                                                        as={Slide}
                                                        title={footerLinkItem.text}
                                                        href={footerLinkItem.url}
                                                        noCrawl={footerLinkItem.noCrawl}
                                                    >
                                                        {footerLinkItem.text}
                                                    </ExternalLink>
                                                </li>
                                            ))}
                                        </Ol>
                                    </ListWrapper>
                                ))}
                            </Ul>
                        </MenuWrapper>
                    )}
                    <GridContainer>
                        <Container>
                            <ImageWrapper>
                                {!!footerLogo.image && (
                                    <LogoImage href={footerLogo.url}>
                                        <LogoImagePic src={footerLogo.image.url} alt={footerLogo.text} />
                                    </LogoImage>
                                )}
                            </ImageWrapper>
                            <IconContainer>
                                <IconUl>
                                    {socialMediaLinks.map(socialMediaLink => (
                                        <IconLi key={socialMediaLink.text}>
                                            <ExternalLink
                                                as={IconContent}
                                                href={socialMediaLink.url}
                                                noCrawl={socialMediaLink.noCrawl}
                                            >
                                                <Image
                                                    src={socialMediaLink.image?.url}
                                                    alt={socialMediaLink.image?.alt}
                                                />
                                            </ExternalLink>
                                        </IconLi>
                                    ))}
                                </IconUl>
                            </IconContainer>
                            <Fragment>
                                {isMobile && ( // Render the button only on mobile devices
                                    <FooterButton onClick={toggleSidebar}>{t('Kontakt, Impressum & mehr')}</FooterButton>
                                )}
                                {isMobile && visible && ( // Render the sidebar only on mobile devices if visible
                                    <Sidebar
                                        visible={visible}
                                        toggleSidebar={toggleSidebar}
                                        menuIcon={''}
                                        topText={t('footerMenu')}
                                        closeIcon={<IconImage as={CloseRegularIcon as AnyStyledComponent} />}
                                    >
                                        <MenuWrapper>
                                            <Ul>
                                                {footerGroups.map(item => (
                                                    <li key={item.groupName}>
                                                        <Ol>
                                                            <li>
                                                                <Title>{item.groupName}</Title>
                                                            </li>
                                                            {item.footerLinkItems.map(footerLinkItem => (
                                                                <li key={footerLinkItem.text}>
                                                                    <ExternalLink
                                                                        as={Slide}
                                                                        title={footerLinkItem.text}
                                                                        href={footerLinkItem.url}
                                                                        noCrawl={footerLinkItem.noCrawl}
                                                                    >
                                                                        {footerLinkItem.text}
                                                                    </ExternalLink>
                                                                </li>
                                                            ))}
                                                        </Ol>
                                                    </li>
                                                ))}
                                            </Ul>
                                        </MenuWrapper>
                                    </Sidebar>
                                )}
                            </Fragment>
                        </Container>
                    </GridContainer>
                </InnerContainerWrapper>
            </FooterWrapper>
        </Footer>
    )
}

export default memo(FooterSection)
