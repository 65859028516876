export interface Location {
    regionId: number | null
    search: string
}

// Guests Picker types
export enum GuestType {
    GUEST_TYPE_ADULT = 'adultsGuest',
    GUEST_TYPE_CHILDREN = 'childrenGuest',
}

// User Menu types
export enum Role {
    CUSTOMER = 'customer', // landlord
    TOURIST = 'tourist', // guest
}
