import React, { useEffect } from 'react'
import { DrawerCheckbox, DrawerNav, DrawerOverlay, DrawerWrapper } from './drawer-styles'

export enum Direction {
    left,
    right,
    top,
    bottom,
}

interface Props {
    open: boolean
    onClose?: () => void
    direction: Direction
    children?: React.ReactNode
    duration?: number
    overlayOpacity?: number
    overlayColor?: string
    enableOverlay?: boolean
    style?: React.CSSProperties
    zIndex?: number
    size?: number | string
    className?: string
    id: string
}

const getDirectionStyle = (dir: Direction, size?: number | string): {} | React.CSSProperties => {
    switch (dir) {
        case Direction.left:
            return {
                top: 0,
                left: 0,
                transform: 'translate3d(-100%, 0, 0)',
                width: size,
                height: '100vh',
            }
        case Direction.right:
            return {
                top: 0,
                right: 0,
                transform: 'translate3d(100%, 0, 0)',
                width: size,
                height: '100vh',
            }
        case Direction.bottom:
            return {
                left: 0,
                right: 0,
                bottom: 0,
                transform: 'translate3d(0, 100%, 0)',
                width: '100%',
                height: size,
            }
        case Direction.top:
            return {
                left: 0,
                right: 0,
                top: 0,
                transform: 'translate3d(0, -100%, 0)',
                width: '100%',
                height: size,
            }

        default:
            return {}
    }
}

const Drawer: React.FC<Props> = function ({
    open,
    onClose = () => {
        /**/
    },
    children,
    style,
    enableOverlay = true,
    overlayColor = 'rgba(33,33,33)',
    overlayOpacity = 0.8,
    zIndex = 100,
    duration = 500,
    direction,
    size = 250,
    className,
    id,
}): JSX.Element {
    const overlayStyles: React.CSSProperties = {
        backgroundColor: overlayColor,
        opacity: overlayOpacity,
        zIndex: zIndex,
    }

    const drawerStyles: React.CSSProperties = {
        zIndex: zIndex + 1,
        transitionDuration: `${duration}ms`,
        ...getDirectionStyle(direction, size),
        ...style,
    }

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'unset'
    }, [open])

    return (
        <DrawerWrapper>
            <DrawerCheckbox type="checkbox" id={id} onChange={onClose} checked={open} />
            <DrawerNav role="navigation" style={drawerStyles} className={className} direction={direction}>
                {children}
            </DrawerNav>
            {enableOverlay && <DrawerOverlay htmlFor={id} style={overlayStyles} />}
        </DrawerWrapper>
    )
}

export default Drawer
