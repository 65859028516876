import React, { FC, Fragment } from 'react'
import { Collapsible, CollapsibleContent, CollapsibleHeader } from 'react-components'
import {
    Container,
    Title,
    TitleContainer,
    DirectoryLinkList,
    DirectoryLink,
    InnerContainer,
} from './directory-item-styles'
import { ChevronUpIcon, ChevronDownIcon } from '../common/icons'
import ExternalLink from '../common/external-link'
import { DirectoryItemTransport } from '../../transports/directory-transport'

type Props = DirectoryItemTransport

const DirectoryItem: FC<Props> = ({ heading, links }) => {
    return (
        <Container>
            <Collapsible initialOpen={false} transitionTime={300}>
                {({ isOpened }) => (
                    <Fragment>
                        <CollapsibleHeader>
                            <TitleContainer isOpened={isOpened}>
                                <Title>{heading}</Title>
                                {isOpened ? <ChevronUpIcon alt="Up Logo" /> : <ChevronDownIcon alt="Down Logo" />}
                            </TitleContainer>
                        </CollapsibleHeader>
                        <CollapsibleContent>
                            <InnerContainer>
                                <DirectoryLinkList>
                                    {links.map((link, index) => (
                                        <ExternalLink key={index} as={DirectoryLink} href={link.url} target="_self">
                                            {link.title}
                                        </ExternalLink>
                                    ))}
                                </DirectoryLinkList>
                            </InnerContainer>
                        </CollapsibleContent>
                    </Fragment>
                )}
            </Collapsible>
        </Container>
    )
}

export default DirectoryItem
