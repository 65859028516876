import styled, { keyframes } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint } from 'react-components'

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`

export const Container = styled.div`
    animation: ${fadeIn} 0.5s ease-in-out;
`

export const TitleContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    line-height: 1.6;
    align-items: ${props => props.theme.style.alignItems.center};
    border-bottom: ${props => props.theme.typography.border.width.xxxThin} solid
        ${props => props.theme.colors.tfwBlackOpacity10};
    transition: padding 200ms ease-out;
    padding: 1rem 1rem 1rem 0rem;
    cursor: pointer;

    &:hover {
        border-bottom-color: ${props => props.theme.colors.tfwBlack87};
        padding: 1rem 1rem 1rem 1rem;
        &:before {
            padding: 1rem 1rem 1rem 0;
            transition: left 200ms ease-out, right 200ms ease-out;
            left: 0;
            right: 0;
        }
    }
`

export const Title = styled.div`
    width: ${props => props.theme.layout.fill};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    white-space: nowrap;
    overflow: ${props => props.theme.style.overflow.hidden};
    text-overflow: ellipsis;
    line-height: 1.5rem;
`

export const DirectoryLink = styled.a`
    opacity: 0.8;
    display: inline-block;
    padding: 0.5em 0;
    font: ${({ theme }) =>
        `${theme.typography.font.weight.normal} ${theme.typography.font.size.xSmall} / 1rem ${theme.typography.font.family.latoVerdana}`};
    text-decoration: none;
    transition: opacity 0.2s, transform 0.2s;
    letter-spacing: 0.0125rem;
    color: #333;

    &:hover {
        opacity: 1;
        transform: scale(1.02);
        text-decoration: underline;
        font-weight: ${({ theme }) => `${theme.typography.font.weight.bold}`};
    }
`

export const DirectoryLinkList = styled.ul`
    padding: 6px 0;
    list-style: none;
    display: grid;
    gap: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

export const InnerContainer = styled.div`
    padding: 5px 10px;
    background-color: #fafafa;
`
