import React, { memo, useContext } from 'react'
import { AnyStyledComponent } from 'styled-components'
import { ScreenContext } from 'react-components'

import {
    ButtonWrapper,
    GhostButtonContent,
    IconImage,
    LogoImage,
    LogoWrapper,
    DrawerLandlordButtonWrapper,
    DrawerItem,
    DrawerItemLeft,
    DrawerItemText,
    MenuDrawerHeader,
    EventPill,
} from './header-section-styles'
import Drawer, { Direction } from '../../common/drawer'
import GhostButton from '../../common/ghost-button'
import { HeaderSectionTransport } from '../../../transports/region-landing-page-transport'
import { useTranslation } from '../../../hooks/locale'

import CloseRegularIcon from '../../../assets/icons/close-regular.svg'
import HelpIcon from '../../../assets/icons/userbar-icons/help.svg'
import BulbIcon from '../../../assets/icons/userbar-icons/bulb.svg'
import BuildingIcon from '../../../assets/icons/userbar-icons/building.svg'
import OfferIcon from '../../../assets/icons/userbar-icons/offer.svg'
import EventIcon from '../../../assets/icons/userbar-icons/event.svg'
import RightIcon from '../../../assets/icons/chevron-right.svg'

interface Props {
    isLoggedIn: boolean
    open: boolean
    showVacationIdeasNavButton: boolean
    onClose: () => void
    headerSection: HeaderSectionTransport
    listRentalClickHandler: () => void
    isLandlordRole?: boolean
    isGuestRole?: boolean
}

const MenuDrawer: React.FC<Props> = ({
    isLoggedIn,
    open,
    showVacationIdeasNavButton = true,
    onClose,
    headerSection,
    listRentalClickHandler,
    isLandlordRole,
}) => {
    const { isMobile, isTablet } = useContext(ScreenContext)
    const { t } = useTranslation()
    const {
        logo,
        vacationIdeasButton,
        offersButton,
        buyPropertyButton,
        helpButton,
        activitiesButton,
        listRentalButton,
    } = headerSection.navigationSectionResponse.navigation

    return (
        <Drawer open={open} onClose={onClose} direction={Direction.right} id="sidebar" size={isMobile ? '85%' : '40%'}>
            <MenuDrawerHeader>
                <LogoWrapper href={logo.url}>
                    <LogoImage src={logo.image ? logo.image.url : ''} alt={'Logo'} />
                </LogoWrapper>
                <ButtonWrapper onClick={onClose}>
                    <IconImage as={CloseRegularIcon as AnyStyledComponent} />
                </ButtonWrapper>
            </MenuDrawerHeader>
            {!!vacationIdeasButton?.url && showVacationIdeasNavButton && (
                <DrawerItem href={vacationIdeasButton.url} onClick={onClose} noCrawl={vacationIdeasButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={BulbIcon as AnyStyledComponent} />
                        <DrawerItemText>{vacationIdeasButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {!!offersButton?.url && (
                <DrawerItem href={offersButton.url} onClick={onClose} noCrawl={offersButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={OfferIcon as AnyStyledComponent} />
                        <DrawerItemText>{offersButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {!!buyPropertyButton?.url && (
                <DrawerItem href={buyPropertyButton.url} onClick={onClose} noCrawl={buyPropertyButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={BuildingIcon as AnyStyledComponent} />
                        <DrawerItemText>{buyPropertyButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {!!activitiesButton?.url && (
                <DrawerItem href={activitiesButton.url} onClick={onClose} noCrawl={activitiesButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={EventIcon as AnyStyledComponent} />
                        <DrawerItemText>{activitiesButton.text}</DrawerItemText>
                        <EventPill>{t('new')}</EventPill>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {!!helpButton?.url && (
                <DrawerItem href={helpButton.url} onClick={onClose} noCrawl={helpButton.noCrawl}>
                    <DrawerItemLeft>
                        <IconImage as={HelpIcon as AnyStyledComponent} />
                        <DrawerItemText>{helpButton.text}</DrawerItemText>
                    </DrawerItemLeft>
                    <IconImage as={RightIcon as AnyStyledComponent} />
                </DrawerItem>
            )}
            {(isMobile || isTablet) && !!listRentalButton?.url && (isLoggedIn ? !isLandlordRole : true) && (
                <DrawerLandlordButtonWrapper>
                    <GhostButton onClick={listRentalClickHandler} padding={'8px 16px'} hoverEffect>
                        <GhostButtonContent>{listRentalButton.text}</GhostButtonContent>
                    </GhostButton>
                </DrawerLandlordButtonWrapper>
            )}
        </Drawer>
    )
}

export default memo(MenuDrawer)
