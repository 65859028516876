import styled, { css } from 'styled-components'
import { Direction } from './index'

export const DrawerWrapper = styled.div``

export const DrawerCheckbox = styled.input`
    display: none;
`

export const DrawerNav = styled.nav<{ direction: Direction }>(
    ({ theme, direction }) => css`
        position: fixed;
        visibility: hidden;
        background: ${theme.colors.white};
        transition: all;
        ${direction === Direction.bottom &&
        css`
            border-radius: ${theme.typography.border.radius.xLarge} ${theme.typography.border.radius.xLarge} 0 0;
        `};

        ${DrawerCheckbox}:checked ~ & {
            visibility: visible;
            transform: translate3d(0, 0, 0) !important;
        }
    `,
)

export const DrawerOverlay = styled.label(
    ({ theme }) => css`
        display: none;
        height: ${theme.layout.fill};
        left: 0;
        position: fixed;
        top: 0;
        width: ${theme.layout.fill};

        ${DrawerCheckbox}:checked ~ & {
            display: block;
            opacity: 1;
        }
    `,
)
